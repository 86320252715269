import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaBars, FaTimes } from 'react-icons/fa';
import { logo } from '../assets/index';

const Navbar = () => {
  const navbarItems = [
    { name:'Home', route: '/' },
    { name:'Sobre', route: '/sobre' },
    { name:'Projetos', route: '/projetos' },
  ];

  const [nav, setNav] = useState(false);
  const handleNav = () => setNav(!nav);

  const navigate = useNavigate();

  const scrollToTop = (isContactsButton) => {
    // Use the navigate function to update the URL
    navigate('/');

    // Scroll to the top of the page or to the 'contacts' element based on the button clicked
    if (isContactsButton) {
      setTimeout(() => {
        const contactsElement = document.getElementById('contactos');
        if (contactsElement) {
          contactsElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 0);
    } else {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <div className='fixed w-full h-20 md:h-36 flex justify-between md:justify-center items-center px-5 md:px-24 bg-bgNeutral z-10 border-b border-secondaryLight'>
      <div>
        <div className='flex justify-center'>
          <Link to='/' onClick={() => scrollToTop(false)}>
            <img src={logo} alt='Sizz Design Logo' className='w-16 md:w-20' />
          </Link>
        </div>

        {/* divider */}
        <div
          className='absolute inset-0 flex items-center top-[88px] h-1'
          aria-hidden='true'
        >
          <div className='w-full md:border-t md:border-secondaryLight' />
        </div>

        {/* menu */}
        <nav className='relative z-50'>
          <ul className='hidden md:flex gap-10 mt-8'>
            {navbarItems.map((item) => (
              <li key={`menu_${item.name}`} className='text-secondaryBrown text-sm'>
                <Link to={item.route} onClick={() => scrollToTop(false)}>
                  {item.name.toUpperCase()}
                </Link>
              </li>
            ))}
            <button
              className='text-secondaryBrown text-sm'
              type='button'
              onClick={() => scrollToTop(true)}
            >
              CONTACTOS
            </button>
          </ul>
        </nav>
      </div>

      {/* hamburger */}
      <button type='button' onClick={handleNav} className='md:hidden z-10'>
        {!nav ? (
          <FaBars className='text-secondaryBrown text-xl' />
        ) : (
          <FaTimes className='text-almostWhite text-xl' />
        )}
      </button>

      {/* mobile menu */}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-primaryBlue flex flex-col justify-center items-center md:hidden'
        }
      >
        {navbarItems.map((item) => (
          <nav key={`hamburger_${item.name}`}>
            <li className='py-6 text-4xl text-almostWhite'>
              <Link
                to={item.route}
                onClick={() => {
                  scrollToTop(false);
                  handleNav();
                }}
              >
                {item.name.toUpperCase()}
              </Link>
            </li>
          </nav>
        ))}
        <button
          className='py-6 text-4xl text-almostWhite'
          type= 'button'
          onClick={() => {
            scrollToTop(true);
            handleNav();
          }}
        >
          CONTACTOS
        </button>
      </ul>
    </div>
  );
};

export default Navbar;
