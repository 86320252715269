import React, { useEffect, useState } from 'react';
import { config } from '../utils/constants';

const ProjectDetail = ({ project, categories }) => {
  const [categoriesList, setCategoriesList] = useState([...categories.data]);

  // get categories
  useEffect(() => {
    const newCategories = [...categories.data];
    setCategoriesList(newCategories);
  }, [categories.data]);

  const renderProjectCategory = () => {
    const { category } = project.attributes;
    if (!category.parent_id) {
      const projectCategoryId = category.id;
      const projectCategory = categoriesList.find(
        (cat) => cat.attributes.id === projectCategoryId
      );
      return projectCategory.attributes.name;
    }

    const projectSubCategoryId = category.id;
    const projectCategoryId = category.parent_id;
    const projectCategory = categoriesList.find(
      (cat) => cat.attributes.id === projectCategoryId
    );
    const projectSubCategory = projectCategory.attributes.subcategories.find(
      (cat) => cat.id === projectSubCategoryId
    );

    return projectSubCategory
      ? `${projectCategory.attributes.name}, ${projectSubCategory.name}`
      : projectCategory.attributes.name;
  };

  const renderMansonry = () => {
    return (
      <div className='max-w-[1200px] mx-auto px-12 md:py-32 py-20 columns-1 md:columns-2 gap-4 space-y-4'>
        {project.attributes.images.map((img) => (
          <img
            key={img.id}
            src={`${config.API_URL}/${img.url}`}
            alt='Project'
            className='w-full rounded-sm'
          />
        ))}
      </div>
    );
  };

  return (
    <div className='pt-20 md:pt-36 min-h-[calc(100vh-80px-540px)] md:min-h-[calc(100vh-144px-338px)]'>
      <div className='bg-primaryBlue flex flex-col md:flex-row'>
        <div className='md:w-[50%] px-12 flex flex-col justify-center text-almostWhite text-start py-20'>
          <div className='max-w-[600px] mx-auto'>
            <h1 className='text-3xl mb-12 md:mb-20 text-center'>
              {project.attributes.name}
            </h1>
            <div
              style={{
                backgroundImage: `url(${config.API_URL}/${project.attributes.images[0].url})`,
              }}
              className='container bg-no-repeat bg-center bg-cover mb-12 h-[300px] md:hidden'
            />
            {project.attributes.description && (
              <p className='font-medium mb-4'>
                {renderProjectCategory().includes('Arquitectura')
                  ? 'Fase: '
                  : 'Sobre o projeto: '}
                <span className='font-light'>
                  {project.attributes.description}
                </span>
              </p>
            )}
            {categoriesList && (
              <p className='font-medium mb-4'>
                Tipo de projeto:{' '}
                <span className='font-light'>{renderProjectCategory()}</span>
              </p>
            )}
            {project.attributes.location && (
              <p className='font-medium mb-4'>
                Localização:{' '}
                <span className='font-light'>
                  {project.attributes.location}
                </span>
              </p>
            )}
            {project.attributes.year && (
              <p className='font-medium mb-4'>
                Ano:{' '}
                <span className='font-light'>{project.attributes.year}</span>
              </p>
            )}
            {project.attributes.photography && (
              <p className='font-medium'>
                Fotografia:{' '}
                <span className='font-light'>
                  {project.attributes.photography}
                </span>
              </p>
            )}
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${config.API_URL}/${project.attributes.images[0].url})`,
          }}
          className='container bg-no-repeat bg-center bg-cover h-[calc(100vh-80px)] md:h-[calc(100vh-144px)] md:w-[50%] hidden md:block'
        />
      </div>
      {renderMansonry()}
    </div>
  );
};

export default ProjectDetail;
