import React from 'react';
import { FaSquareInstagram } from 'react-icons/fa6';
import { FaFacebookSquare } from 'react-icons/fa';
import { logo } from '../assets/index';

const Footer = () => {
  return (
    <div className='w-full min-h-min py-20 justify-between items-center px-5 md:px-24 bg-bgNeutral z-10 md:flex justify-between border-t border-secondaryLight'>
      <div className='flex flex-col justify-center z-10 mb-10 md:mb-0'>
        <img src={logo} alt='Sizz Design Logo' className='w-20 md:w-32 mb-4' />
        <ul className='flex text-6xl gap-2'>
          <a
            href='https://www.instagram.com/sizz.design/'
            target='_blank'
            rel='noreferrer'
          >
            <li className='text-primaryLighter hover:text-primaryBlue'>
              <FaSquareInstagram />
            </li>
          </a>
          <a
            href='https://www.facebook.com/sizzdesign/'
            target='_blank'
            rel='noreferrer'
          >
            <li className='text-primaryLighter hover:text-primaryBlue'>
              <FaFacebookSquare />
            </li>
          </a>
        </ul>
      </div>
      <div className='mb-10 md:mb-0'>
        <p className='font-medium mb-2'>Morada</p>
        <p>
          Rua Manuel Rodrigues da Silva 2, Escritório 1
          <br />
          1600-503 Lisboa
        </p>
      </div>
      <div>
        <p className='font-medium mb-2'>Contactos</p>
        <p>(+351) 913242266</p>
        <p>sizz.idesign@gmail.com</p>
      </div>
    </div>
  );
};

export default Footer;
