import React from 'react';
import { MdErrorOutline } from 'react-icons/md';
import { Link } from 'react-router-dom';

const NotFound = ({ error }) => {
  return (
    <div className='flex flex-col justify-center items-center h-[calc(100vh-329px)] md:h-[calc(100vh-329px)] text-primaryBlue'>
      <div className='min-h-full flex items-center justify-center flex-col pt-20 md:pt-36'>
        <div className='flex text-center items-center gap-2 mb-4'>
          <MdErrorOutline className='text-3xl' />
          Ups... Esta página não existe!
        </div>
        <Link
          to='/'
          className='rounded-full py-2 px-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
        >
          Voltar à página inicial
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
