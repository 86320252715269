import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const Loading = () => {
  return (
    <div className='flex flex-col justify-center items-center h-screen text-primaryBlue'>
      <FaSpinner className='text-3xl text-teal-800 animate-spin mb-4' />
    </div>
  );
};

export default Loading;
