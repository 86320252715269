import axios from 'axios';
import { config } from '../utils/constants'

export const getCategories = async () => {
  try {
    const response = await axios.get(`${config.API_URL}/categories`);
    return response.data;
  } catch (error) {
    throw new Error('Erro ao carregar a lista de categorias');
  }
};
