import React from 'react';
import { rita, mariana } from '../assets/index'

const About = () => {
  localStorage.clear()
  localStorage.clear()

  const renderAbout = () => {
    return (
      <>
        <div className='min-h-min bg-primaryBlue'>
          <div className='max-w-[1000px] mx-auto px-12 py-24 flex flex-col justify-center text-almostWhite text-center'>
            <h2 className='text-3xl mb-10'>SOBRE</h2>
            <p className='font-light max-w-[650px] mx-auto'>
              Somos um atelier de arquitectura e design de interiores onde os
              nossos clientes se tornam o nosso maior foco. A procura de
              soluções diferentes e interessantes para os nossos projectos,
              são o nosso dia a dia. Desenvolvemos todo o tipo de projectos,
              residenciais, comerciais ou de hotelaria, sempre com dedicação e
              dando sempre o melhor. Não temos um estilo definido, somos
              camaleónicas e por isso adaptamo-nos ao estilo do cliente.
            </p>
          </div>
        </div>

        <div className='max-w-[1000px] mx-auto px-12 py-24 flex flex-col justify-center text-secondaryBrown text-center'>
          <div>
            <h2 className='text-3xl mb-10'>AS SIZZ</h2>
            <p className='font-light max-w-[650px] mx-auto'>
              A Sizz Design foi criada pela paixão de duas colegas e amigas
              que trabalharam juntas num atelier português de interiores, e
              num momento decidiram arriscar sozinhas no mundo da arquitectura
              e dos interiores.
            </p>
          </div>
          <div className='mt-16 grid sm:grid-cols-1 md:grid-cols-2 gap-16'>
            <div className='flex flex-col justify-center items-center mx-auto'>
              <img
                src={rita}
                alt='Rita'
                className='rounded-sm aspect-square object-center object-cover'
              />
              <p className='mt-6 max-w-80 font-light'>
                Rita Paz Gordo, designer de interiores, licenciou-se pela KLC
                School of Design em Londres, onde numa base diária estava
                rodeada de arte e criatividade o que a tornou uma profissional
                muito completa e com atenção ao detalhe.
              </p>
            </div>
            <div className='flex flex-col justify-center items-center mx-auto'>
              <img
                src={mariana}
                alt='Mariana'
                className='rounded-sm aspect-square object-center object-cover'
              />
              <p className='mt-6 max-w-80 font-light'>
                Mariana Fernandes, arquitecta, licenciou-se pela Faculdade de
                Arquitectura de Lisboa. O seu talento natural e dedicação ao
                trabalho torna-a uma profissional exímia e com um sentido
                criativo muito apurado.
              </p>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='pt-20 md:pt-36 min-h-[calc(100vh-80px-540px)] md:min-h-[calc(100vh-144px-338px)]'>
      {renderAbout()}
    </div>
  );
};

export default About;
