import { useCallback, useEffect, useState } from 'react';
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';

const ImagesSlider = ({ slides, initialIndex }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  const prevSlide = useCallback(() => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides.length]);

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides.length]);

  const goToSlide = (slideIndex) => {
    setCurrentIndex(slideIndex);
  };

  // handles left and right arrow keys press
  useEffect(() => {
    const arrows = (e) => {
      if (e.keyCode === 37) {
        prevSlide();
      } else if (e.keyCode === 39) {
        nextSlide();
      }
    };
    window.addEventListener('keydown', arrows);
    return () => window.removeEventListener('keydown', arrows);
  }, [prevSlide, nextSlide]);

  return (
    <div className='w-screen h-[calc(100vh-80px)] md:h-[calc(100vh-144px)] relative group'>
      {/* Image */}
      <div
        style={{
          backgroundImage: slides
            ? `url(${slides.map((img) => img.url)[currentIndex]})`
            : 'none',
          backgroundColor: slides ? 'transparent' : 'bg-almostBlack',
        }}
        className='w-full h-full bg-center bg-cover bg-no-repeat transition-all duration-500'
      >
        <div className='whitespace-nowrap px-5 md:px-10 py-12 text-lg sm:text-xl text-almostWhite flex justify-center items-end h-full transition-all duration-500'>
          <p className='[text-shadow:_1px_1px_1px_rgb(30_30_30_/_80%)]'>
            {slides.map((img) => img.projectName)[currentIndex]}
          </p>
          <p>&nbsp; – &nbsp;</p>
          <p className='[text-shadow:_1px_1px_1px_rgb(30_30_30_/_80%)]'>
            {slides.map((img) => img.projectYear)[currentIndex]}
          </p>
        </div>
      </div>

      {/* Left Arrow */}
      <div className='block absolute top-[47%] left-[1%] text-2xl rounded-full p-2 bg-almostWhite/10 hover:bg-almostWhite/30 text-almostWhite cursor-pointer font-bold'>
        <BsChevronCompactLeft onClick={prevSlide} size={30} />
      </div>

      {/* Right Arrow */}
      <div className='block absolute top-[47%] right-[1%] text-2xl rounded-full p-2 bg-almostWhite/10 hover:bg-almostWhite/30 text-almostWhite cursor-pointer font-bold'>
        <BsChevronCompactRight onClick={nextSlide} size={30} />
      </div>

      {/* Dots */}
      <div className='flex absolute bottom-[1.5%] left-[calc(50%-36px)]'>
        {slides.map((slide, slideIndex) => (
          <button
            key={slide}
            type='button'
            onClick={() => goToSlide(slideIndex)}
            className={`text-2xl cursor-pointer hover:text-almostWhite ${
              slideIndex === currentIndex
                ? 'text-almostWhite'
                : 'text-almostWhite/50'
            }`}
          >
            <RxDotFilled />
          </button>
        ))}
      </div>
    </div>
  );
};

export default ImagesSlider;
