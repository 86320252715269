import axios from 'axios';
import { config } from '../utils/constants';

export const postEmail = async (params) => {
  try {
    const response = await axios.post(`${config.API_URL}/send_email`, {
      params,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
