import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { ParallaxBanner } from 'react-scroll-parallax';
import ImagesSlider from './ImagesSlider';
import { postEmail } from '../services/email';
import { CiCircleCheck } from 'react-icons/ci';
import { MdErrorOutline } from 'react-icons/md';
import { config } from '../utils/constants'

import {
  banner1,
  banner2,
  banner3,
  parallax,
} from '../assets/index';

const HomePage = ({projects}) => {
  localStorage.clear();
  localStorage.clear();

  const images = [
    {
      url: banner1,
      projectName: 'Hotel 1908',
      projectYear: '2017',
    },
    {
      url: banner2,
      projectName: 'Apartamento em Campolide',
      projectYear: '2019',
    },
    {
      url: banner3,
      projectName: 'Apartamento em Alvalade',
      projectYear: '2022',
    },
  ];

  const [sentEmail, setSentEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [recentProjects, setRecentProjects] = useState(projects);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const compareByDate = (a, b) => {
    const dateA = new Date(a.attributes.year);
    const dateB = new Date(b.attributes.year);
    return dateA - dateB;
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setRecentProjects(
      [...recentProjects].sort(compareByDate).reverse().slice(0, 6)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // carousel images automatically change
  useEffect(() => {
    if (currentIndex === images.length) {
      setCurrentIndex(0);
    }

    setTimeout(() => {
      setCurrentIndex(currentIndex + 1);
    }, 4000);
  }, [currentIndex, images.length]);

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const emailData = await postEmail(formData);
      setSentEmail(emailData);
      setFormData({
        name: '',
        email: '',
        subject: '',
        message: '',
      });
    } catch (error) {
      if (error?.response?.data?.error) {
        // Display the custom error message from the backend
        setEmailError(error.response.data.error);
        console.log(error.response.data.error);
      } else {
        // Default error message if the format is not as expected
        setEmailError('An error occurred while sending the email.');
      }
    } finally {
      setTimeout(() => {
        setEmailError(null);
        setSentEmail(null);
      }, 5000);
    }
  };

  const renderMiniBanner = () => {
    return (
      <div className='w-full h-32 flex bg-almostBlack z-10 flex justify-start items-center px-5 md:px-24 py-10'>
        <div className='text-almostWhite'>
          <h2 className='text-xl'>
            Atelier de Arquitectura e Design de Interiores
          </h2>
          <h3 className='font-light hidden md:block'>
            Projectos residenciais, comerciais e hotelaria.
          </h3>
        </div>
      </div>
    );
  };

  const renderAbout = () => {
    return (
      <div className='h-[50vh] max-w-[800px] mx-auto px-12 flex flex-col justify-center text-secondaryBrown text-center'>
        <h2 className='text-3xl mb-10'>SOBRE</h2>
        <p className='font-light max-w-[400px] mx-auto'>
          A procura de soluções diferentes e interessantes para os nossos
          projectos, são o nosso dia a dia.
        </p>
        <div className='mt-8'>
          <Link
            to='/sobre'
            className='rounded-full py-2 px-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
          >
            Saber mais
          </Link>
        </div>
      </div>
    );
  };

  const renderParallaxBanner = () => {
    return (
      <ParallaxBanner
        layers={[{ image: parallax, speed: -40 }]}
        className='h-[60vh]'
      />
    );
  };

  const renderRecentProjects = () => {
    return (
      <div className='min-h-screen max-w-[1200px] mx-auto px-12 py-32 flex flex-col justify-center text-secondaryBrown text-center'>
        <h2 className='text-3xl mb-16'>PROJETOS RECENTES</h2>

        <div className='grid sm:grid-cols-1 md:grid-cols-3 gap-4'>
          {/* cards  */}
          {recentProjects.map((item) => (
            <a
              href={`/projetos/${item.attributes.id}`}
              key={`projects${item.attributes.id}`}
            >
              <div
                style={{
                  backgroundImage: `url(${config.API_URL}/${item.attributes.images[0].url})`,
                }}
                className='rounded-sm group container flex justify-center items-center mx-auto bg-no-repeat bg-center bg-cover h-80'
              >
                {/* hover effect */}
                <div
                  className='rounded-sm opacity-0 group-hover:opacity-100 w-full h-full flex justify-center items-center mx-auto bg-no-repeat bg-center bg-cover h-80 px-4 text-xl tracking-wider text-almostWhite flex justify-center'
                  style={{
                    backgroundImage: `linear-gradient(to bottom, rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.8)), url(${config.API_URL}/${item.attributes.images[0].url})`,
                  }}
                >
                  {item.attributes.name}
                  {item.attributes.year && ` – ${item.attributes.year}`}
                </div>
              </div>
            </a>
          ))}
        </div>

        <div className='mt-16'>
          <Link
            to='/projetos'
            className='rounded-full py-2 px-3 border border-secondaryBrown max-w-fit hover:bg-almostBlack hover:text-almostWhite hover:border-almostWhite'
          >
            Todos os projetos
          </Link>
        </div>
      </div>
    );
  };

  const renderContactForm = () => {
    return (
      <div id='contactos' className='bg-primaryBlue md:scroll-mt-24'>
        <div className='min-h-min max-w-[800px] mx-auto px-12 py-24 flex flex-col justify-center text-almostWhite'>
          <h2
            className={`text-3xl text-center ${
              emailError || sentEmail ? 'mb-8' : 'mb-20'
            }`}
          >
            FALE CONNOSCO
          </h2>
          {sentEmail && (
            <div className='mb-8 flex gap-2 items-center'>
              <CiCircleCheck className='text-xl' />
              {sentEmail.message}
            </div>
          )}
          {emailError && (
            <div className='mb-8 flex gap-2 items-center'>
              <MdErrorOutline className='text-xl' />
              {emailError}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className='grid sm:grid-cols-1 md:grid-cols-2 gap-12'>
              <div className='flex flex-col'>
                <label>Nome</label>
                <input
                  className='bg-primaryBlue border-b py-2 outline-none'
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </div>
              <div className='flex flex-col'>
                <label>Email *</label>
                <input
                  className='bg-primaryBlue border-b py-2 outline-none'
                  type='text'
                  name='email'
                  required
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className='flex flex-col mt-12'>
              <label>Assunto *</label>
              <input
                className='bg-primaryBlue border-b py-2 outline-none'
                type='text'
                name='subject'
                required
                value={formData.subject}
                onChange={handleInputChange}
              />
            </div>
            <div className='flex flex-col mt-12'>
              <label>Mensagem *</label>
              <textarea
                className='bg-primaryBlue border-b py-2 h-20 outline-none'
                type='text'
                name='message'
                required
                value={formData.message}
                onChange={handleInputChange}
              />
            </div>
            <div className='w-full text-center'>
              <input
                className='rounded-full text-center mt-20 py-2 px-3 border border-almostWhite hover:bg-almostWhite hover:text-primaryBlue hover:border-primaryBlue hover:cursor-pointer'
                type='submit'
                value='Submeter'
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className='pt-20 md:pt-36'>
      <ImagesSlider slides={images} initialIndex={currentIndex} />
      {renderMiniBanner()}
      {renderAbout()}
      {renderParallaxBanner()}
      {renderRecentProjects()}
      {renderContactForm()}
    </div>
  );
}

export default HomePage
