import React, { useState, useEffect } from 'react';
import { IoFilterSharp } from 'react-icons/io5';
import { config } from '../utils/constants';

const Projects = ({ projects, categories }) => {
  const [categoriesList, setCategoriesList] = useState([...categories.data]);

  const [originalProjects, setOriginalProjects] = useState(() => {
    return (
      JSON.parse(localStorage.getItem('originalProjects')) || [...projects.data]
    );
  });
  const [projectsList, setProjectsList] = useState(() => {
    return (
      JSON.parse(localStorage.getItem('projectsList')) || [...projects.data]
    );
  });
  const [projectsListFiltered, setProjectsListFiltered] = useState(() => {
    return JSON.parse(localStorage.getItem('projectsListFiltered')) || [];
  });

  const [activeFilter, setActiveFilter] = useState(() => {
    return Number(localStorage.getItem('activeFilter')) || 0;
  });
  const [activeSubFilter, setActiveSubFilter] = useState(() => {
    return Number(localStorage.getItem('activeSubFilter')) || 0;
  });

  const [showFilters, setShowFilters] = useState(() => {
    return JSON.parse(localStorage.getItem('showFilters')) || false;
  });
  const [showSelectedFilters, setShowSelectedFilters] = useState(() => {
    return JSON.parse(localStorage.getItem('showSelectedFilters')) || false;
  });

  // get categories
  useEffect(() => {
    const newCategories = [...categories.data];
    setCategoriesList(newCategories);
  }, [categories.data]);

  // set filters and subfilters
  const filters = () => {
    const newCategories = [...categoriesList];
    newCategories.unshift({
      id: '0',
      type: 'category',
      attributes: {
        id: 0,
        name: 'Todos',
        subcategories: [],
      },
    });

    return newCategories;
  };

  const subFilters = () => {
    const newCategories = [...categoriesList];
    const subCategoriesList = {};
    newCategories.map((cat) => {
      const newSubCategories = [...cat.attributes.subcategories];
      newSubCategories.unshift({
        id: 0,
        name: 'Todos',
      });

      const subCatList = cat.attributes.subcategories.length > 0
        ? newSubCategories
        : cat.attributes.subcategories

      // biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
      return  subCategoriesList[cat.attributes.id] = subCatList;
    });

    return subCategoriesList;
  };

  const compareByDate = (a, b) => {
    const dateA = new Date(a.attributes.year);
    const dateB = new Date(b.attributes.year);
    return dateA - dateB;
  };

  // get projects
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const newProjects = [...projects.data];
    setOriginalProjects(newProjects.sort(compareByDate).reverse());
  }, [projects.data]);

  // Effect to update localStorage whenever relevant state changes
  useEffect(() => {
    localStorage.setItem('activeFilter', activeFilter.toString());
  }, [activeFilter]);

  useEffect(() => {
    localStorage.setItem('activeSubFilter', activeSubFilter.toString());
  }, [activeSubFilter]);

  useEffect(() => {
    localStorage.setItem('showFilters', JSON.stringify(showFilters));
  }, [showFilters]);

  useEffect(() => {
    localStorage.setItem(
      'showSelectedFilters',
      JSON.stringify(showSelectedFilters)
    );
  }, [showSelectedFilters]);

  useEffect(() => {
    localStorage.setItem('originalProjects', JSON.stringify(originalProjects));
  }, [originalProjects]);

  useEffect(() => {
    localStorage.setItem('projectsList', JSON.stringify(projectsList));
  }, [projectsList]);

  useEffect(() => {
    localStorage.setItem(
      'projectsListFiltered',
      JSON.stringify(projectsListFiltered)
    );
  }, [projectsListFiltered]);

  const handleFiltersClick = (filter) => {
    setActiveFilter(filter.attributes.id);
    setActiveSubFilter(0);
    if (filter.attributes.id === 0) {
      setProjectsListFiltered(originalProjects);
      setShowSelectedFilters(false);
    } else {
      const selectedProjects = originalProjects.filter((project) =>
        project.attributes.category.parent_id
          ? project.attributes.category.parent_id === filter.attributes.id
          : project.attributes.category.id === filter.attributes.id
      );
      setProjectsListFiltered(selectedProjects);
      setShowSelectedFilters(true);
    }
  };

  const handleSubFiltersClick = (filter) => {
    setActiveSubFilter(filter.id);
    if (filter.id === 0) {
      setProjectsList(projectsListFiltered);
    } else {
      const selectedProjects = projectsListFiltered.filter(
        (project) => project.attributes.category.id === filter.id
      );
      setProjectsList(selectedProjects);
    }
  };

  const renderFiltersButton = () => {
    return (
      <div>
        <button
          type='button'
          onClick={() => setShowFilters(!showFilters)}
          className={`${
            showFilters === true
              ? 'bg-almostBlack text-almostWhite border-almostWhite'
              : 'text-secondaryBrown border-secondaryBrown'
          } aspect-square rounded-full py-2 px-3 border max-w-fit hover:cursor-pointer`}
        >
          <IoFilterSharp />
        </button>
      </div>
    );
  };

  const renderFilters = () => {
    return (
      <div className='flex flex-col justify-center gap-3'>
        {filters().map((filter) => (
          <button
            type='button'
            onClick={() => handleFiltersClick(filter)}
            key={`projects${filter.attributes.id}`}
            className={`${
              filter.attributes.id === activeFilter
                ? 'bg-almostBlack text-almostWhite border-almostWhite'
                : 'text-secondaryBrown border-secondaryBrown'
            } rounded-full whitespace-nowrap px-2 border border-secondaryBrown max-w-fit hover:cursor-pointer`}
          >
            {filter.attributes.name}
          </button>
        ))}
      </div>
    );
  };

  const renderSubFilters = () => {
    return (
      subFilters().length !== 0 && (
        <div className='flex flex-col justify-center gap-3 border-t border-secondaryLight pt-6 mt-6'>
          {subFilters()[activeFilter].map((filter) => (
            <button
              type='button'
              onClick={() => handleSubFiltersClick(filter)}
              key={`projects${filter.id}`}
              className={`${
                filter.id === activeSubFilter
                  ? 'bg-almostBlack text-almostWhite border-almostWhite'
                  : 'text-secondaryBrown border-secondaryBrown'
              } rounded-full whitespace-nowrap px-2 border border-secondaryBrown max-w-fit hover:cursor-pointer`}
            >
              {filter.name}
            </button>
          ))}
        </div>
      )
    );
  };

  const renderSelectedFilters = () => {
    const selectedFilters = [];

    if (activeFilter !== 0) {
      const activeF = filters().filter(
        (filter) => filter.attributes.id === activeFilter
      )[0];

      selectedFilters.push(activeF);

      if (activeSubFilter !== 0) {
        const activeSubF = activeF.attributes.subcategories.filter(
          (sf) => sf.id === activeSubFilter
        )[0];

        selectedFilters.push(activeSubF);
      }
    }

    const renderFilterName = (filter) => {
      if (filter.attributes) {
        return filter.attributes.name;
      }
      return filter.name;
    };

    return (
      !!selectedFilters.length && (
        <div className='flex justify-start gap-3 mb-4 flex-col sm:flex-row'>
          {selectedFilters.map((filter) => (
            <button
              type='button'
              key={`projects${Number(filter.id)}`}
              className='bg-almostBlack text-almostWhite border-almostWhite rounded-full whitespace-nowrap px-2 border border-secondaryBrown max-w-fit hover:cursor-pointer'
            >
              {renderFilterName(filter)}
            </button>
          ))}
        </div>
      )
    );
  };

  const renderProjects = () => {
    if (activeSubFilter !== 0) {
      return projectsList;
    }
    if (activeFilter !== 0) {
      return projectsListFiltered;
    }
    return originalProjects;
  }

  return (
    <div className='pt-20 md:pt-36 min-h-[calc(100vh-80px-540px)] md:min-h-[calc(100vh-144px-338px)]'>
      <div className='min-h-min max-w-[1200px] mx-auto px-12 py-24 flex flex-col justify-center text-secondaryBrown text-center'>
        <div
          className={`${
            showSelectedFilters && !showFilters
              ? 'md:mb-8 mb-8'
              : 'md:mb-16 mb-12'
          } flex justify-between items-center`}
        >
          {renderFiltersButton()}
          <h2 className='text-3xl text-secondaryBrown'>PROJETOS</h2>
          <div className='w-[42px]' />
        </div>
        {showSelectedFilters && !showFilters && renderSelectedFilters()}
        <div className='md:flex justify-center'>
          {showFilters && (
            <div className={`${showFilters ? 'mr-10 w-60' : ''} mb-12 md:my-0`}>
              {renderFilters()}
              {!!subFilters()[activeFilter]?.length && renderSubFilters()}
            </div>
          )}
          {projectsList.length > 0? (
            <div className='w-full grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
              {/* cards  */}
              {renderProjects().map((item) => (
                <a
                  href={`/projetos/${item.attributes.id}`}
                  key={`projects${item.attributes.id}`}
                >
                  <div
                    style={{
                      backgroundImage: `url(${config.API_URL}/${item.attributes.images[0].url})`,
                    }}
                    className='rounded-sm group container flex justify-center items-center mx-auto bg-no-repeat bg-center bg-cover h-80'
                  >
                    {/* hover effect */}
                    <div
                      className='rounded-sm opacity-0 group-hover:opacity-100 w-full h-full flex justify-center items-center mx-auto bg-no-repeat bg-center bg-cover h-80 px-4 text-xl tracking-wider text-almostWhite flex justify-center'
                      style={{
                        backgroundImage: `linear-gradient(to bottom, rgba(30, 30, 30, 0.8), rgba(30, 30, 30, 0.8)), url(${config.API_URL}/${item.attributes.images[0].url})`,
                      }}
                    >
                      {item.attributes.name}
                      {item.attributes.year && ` – ${item.attributes.year}`}
                    </div>
                  </div>
                </a>
              ))}
            </div>
          ) : (
            <div className='grow text-start'>
              Não há projetos com estes filtros.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
