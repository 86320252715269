import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import Navbar from "./components/Navbar";
import HomePage from "./components/HomePage";
import About from './components/About';
import Projects from './components/Projects';
import ProjectDetail from './components/ProjectDetail';
import Footer from './components/Footer';
import { getProjects } from './services/projects';
import { getCategories } from './services/categories';
import { useEffect, useState } from 'react';
import Loading from './components/Loading';
import DisplayError from './components/DisplayError';
import NotFound from './components/NotFound';
import InternalServerError from './components/InternalServerError';

function App() {
  const [projects, setProjects] = useState([]);
  const [projectsLoading, setProjectsLoading] = useState(true);
  const [projectsError, setProjectsError] = useState(null);

  const [categories, setCategories] = useState([]);
  const [categoriesError, setCategoriesError] = useState(null);

  // get projects
  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectsData = await getProjects();
        setProjects(projectsData);
      } catch (error) {
        setProjectsError(error);
      } finally {
        setProjectsLoading(false);
      }
    };

    fetchData();
  }, []);

  // get categories
  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesData = await getCategories();
        setCategories(categoriesData);
      } catch (error) {
        setCategoriesError(error);
      }
    };

    fetchData();
  }, []);

  if (projectsLoading) {
    return <Loading/>;
  }

  if (projectsError || categoriesError) {
    if (projectsError) {
      return <DisplayError error={projectsError.message} />;
    }
    return <DisplayError error={categoriesError.message} />;
  }

  return (
    <div className='App overflow-hidden'>
      <ParallaxProvider>
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path='/' element={<HomePage projects={projects?.data} />} />
            <Route path='/sobre' element={<About />} />
            <Route
              path='/projetos'
              element={<Projects projects={projects} categories={categories} />}
            />
            {projects?.data.map((project) => (
              <Route
                key={project?.attributes.id}
                path={`/projetos/${project?.attributes.id}`}
                element={
                  <ProjectDetail project={project} categories={categories} />
                }
              />
            ))}
            <Route path='*' element={<NotFound />} />
            <Route path='/404' element={<NotFound />} />
            <Route path='/500' element={<InternalServerError />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </ParallaxProvider>
    </div>
  );
}

export default App;
